<template>
	<div class="app-main__inner dm-sans-font"
		 style="padding-left: 10px !important;padding-right:10px;padding-top: 20px ">
		<div class="absolute inset-0" style="z-index:9" @click="hideWrapper" v-if='showProductMenu'></div>
		<div class="container mb-3">
			<div class="row">
				<div class="col-md-10">
					<div class="flex justify-content-end w-full">
						<div class="filtering-above-table-prod ml-2">
							<div class="fl-pagetype">
								<div class="cursor-pointer hidden md:block" @click="switchFilter"><i
									:class="!grid ? 'bg-blue-900 text-white' : ''"
									class="iconly-brokenFilter font-medium"></i></div>
								<div class="cursor-pointer" @click="switchGrid"><i
									:class="grid ? 'bg-blue-900 text-white' : ''"
									class="iconly-brokenCategory font-medium"></i></div>
							</div>
						</div>
						<div class="w-full">
							<input type="text" v-model="searchName" @input="searchInputTrigger"
								   class="align-items-center w-full space-x-4 focus:outline-none bg-white border px-3 py-2 rounded-md text-gray-500"
								   v-bind:placeholder="$t('search')">
						</div>

					</div>
				</div>
				<div class="col-md-2">
					<div class="w-full mb-2 lg:md-0">
						<button type="button" v-on:click="downloadExcelDocument"
								class="cursor-pointer text-xs h-full pt-2.5 bg-white pb-2.5 w-full text-center rounded-md border">
							{{ $t('productDownload') }}
						</button>
					</div>
				</div>
			</div>
			<!--			<div class="lg:flex justify-between">-->
			<!--				-->
			<!--				-->
			<!--			</div>-->

			<div>

				<div>
					<div class="row">
						<div class="col-md-3 order-1 order-sm-2">
							<div class="mb-4 product_category bg-white rounded">
								<div class=" grid grid-cols-1 md:grid-cols-1 gap-1 lg:grid-cols-3 gap-3 px-3 py-1">
									<p class="text-base font-semibold col-span-2 ">{{ $t('productCategory') }}</p>
									<p class="sm:text-left md:text-right lg:text-right col-span-1 cursor-pointer clear-category-text"
									   v-on:click="resetCategory">{{
											$t('clearCategoryFilter')
										}}</p>
								</div>
								<hr>
								<div v-if="loadingCategory">
									<div class="w-60 animate-pulse p-1 pb-2" v-for="i in 5" :key="i">
										<div class="h-2 mt-2 ml-2 rounded bg-gray-200 "></div>
										<div class="h-2 mt-2 ml-2 rounded bg-gray-200 "></div>
									</div>
								</div>
								<div class="product-category-scroll" v-else>
									<div class="" v-for="(category,key) in categoryTreeList.results"
										 :key="key">
										<category-tree :category="category"
													   @filterCategory="filterArticlesWithParentCategory"
													   @filterChildCategory="filterArticlesWithCategory"/>
									</div>
								</div>

							</div>
							<!--							<div class="row mt-3" v-if="loadingAddon">-->
							<!--								<div class="col-md-12 h-full" v-for="i in 5" :key="i">-->
							<!--									<div-->
							<!--										class="h-32 w-full flex flex-col animate-pulse bg-white rounded-lg mb-2 space-y-1">-->
							<!--										<div class="h-3 w-1/2 mt-2 ml-2 rounded bg-gray-200"></div>-->
							<!--										<div class="flex justify-between">-->
							<!--											<div>-->
							<!--												<div class="h-2 w-32 mt-2 ml-2 rounded bg-gray-200"></div>-->
							<!--												<div class="h-2 w-32 mt-2 ml-2 rounded bg-gray-200"></div>-->
							<!--												<div class="h-2 w-32 mt-2 ml-2 rounded bg-gray-200"></div>-->
							<!--											</div>-->
							<!--											<div>-->
							<!--												<div class="h-12 w-32 mt-1 mr-10 rounded bg-gray-200"></div>-->
							<!--											</div>-->
							<!--										</div>-->
							<!--										<div class="flex justify-between ml-2">-->
							<!--											<div class="h-7 w-20 mt-2 mr-10 rounded bg-gray-200"></div>-->
							<!--											<div class="h-5 w-32 mt-2 mr-10 rounded bg-gray-200"></div>-->
							<!--										</div>-->
							<!--									</div>-->
							<!--								</div>-->
							<!--							</div>-->
							<!--							<div class="" v-else>-->
							<div class="grid grid-cols-1 gap-1" v-if="addonArticles.length > 0">
								<div class="col-spans-1 mb-4" v-for="(product,key) in addonArticles" :key="key">
									<GridArticleComponent class="w-full" :product="product"
														  :is_recommended="true"
														  :key="`${key}_${product.article_number}_grid_recommended`"
														  :limit="limit" :pagenum="pageNum"
														  :searchname="searchName"/>
								</div>
							</div>
							<!--							</div>-->
						</div>
						<div class="col-md-9 order-sm-2 order-2">
							<div class="row" v-if="loading">
								<div class="col-md-4 h-full" v-for="i in 21" :key="i">
									<div
										class="h-32 w-full flex flex-col animate-pulse bg-white rounded-lg mb-2 space-y-1">
										<div class="h-3 w-1/2 mt-2 ml-2 rounded bg-gray-200"></div>
										<div class="flex justify-between">
											<div>
												<div class="h-2 w-28 mt-2 ml-3 rounded bg-gray-200"></div>
												<div class="h-2 w-28 mt-2 ml-3 rounded bg-gray-200"></div>
												<div class="h-2 w-28 mt-2 ml-3 rounded bg-gray-200"></div>
											</div>
											<div>
												<div class="h-12 w-32 mt-1 mr-10 rounded bg-gray-200"></div>
											</div>
										</div>
										<div class="flex justify-between ml-2">
											<div class="h-7 w-16 mt-2 mr-10 rounded bg-gray-200"></div>
											<div class="h-5 w-28 mt-2 mr-10 rounded bg-gray-200"></div>
										</div>
									</div>
								</div>
							</div>
							<div class="row" v-if="loading === false">
								<div class="col-md-12" v-if="activeCategoryTree.category !== ''">
									<p class="text-base font-semibold mb-3"><span>{{
											activeCategoryTree.category
										}}</span> <span
										v-if="activeCategoryTree.child_category !== ''"> >> {{
											activeCategoryTree.child_category
										}}</span></p>
								</div>
							</div>
							<!--							grid sm:grid-cols-1 gaps md:grid-cols-2 gap-2 lg:grid-cols-3 gap-3-->
							<div class="row"
								 v-if="loading === false" v-show="grid">
								<div class="w-full mb-4 col-md-4 h-full" v-for="(product,key) in GET_ARTICLES.data"
									 :key="key">
									<GridArticleComponent :product="product" :limit="limit" :pagenum="pageNum"
														  :searchname="searchName"
														  :key="`${key}_${product.article_number}_grid`"/>
								</div>
								<!--								<div class="col-md-8 mx-auto" v-if="GET_ARTICLES.data.length < 1">-->
								<!--									<p class="text-center font-semibold">{{ message }}</p>-->
								<!--								</div>-->
							</div>
							<div class="row" v-if="GET_ARTICLES.data.length < 1">
								<div class="col-md-8 mx-auto">
									<p class="text-center font-semibold">{{ message }}</p>
								</div>
							</div>
							<div class="table-responsive rounded-lg bg-white border" v-show="!grid"
								 v-if="loading === false">
								<table style="width: 100%;" id="exampledestroy"
									   class="table table-hover table-bordered">
									<thead class="hidden">
									<tr class="bg-white rounded p-1 border-b-2 shadow-sm">
										<th class="text-xs w-1/2">
											<div class="d-flex justify-content-between">{{
													$t('articleImage')
												}}<span></span>
											</div>
										</th>
										<th class="text-xs">
											<div class="d-flex justify-content-between">{{ $t('name') }}<span></span>
											</div>
										</th>
										<th class="text-xs">
											<div class="d-flex justify-content-between">Num<span></span></div>
										</th>
										<th class="text-xs w-1/2">
											<div class="d-flex justify-content-between">{{
													$t('description')
												}}<span></span>
											</div>
										</th>
										<th class="text-xs">
											<div class="d-flex justify-content-between">{{
													$t('quantity')
												}}<span></span></div>
										</th>
										<th class="text-xs">
											<div class="d-flex justify-content-between">{{ $t('price') }}<span></span>
											</div>
										</th>
										<th class="text-xs">
											<div class="d-flex justify-content-between">{{
													$t('quantity')
												}}<span></span></div>
										</th>
										<th class="text-xs">
											<div class="d-flex justify-content-between">Actions<span></span></div>
										</th>
									</tr>
									</thead>
									<tbody v-for="(product,key) in GET_ARTICLES.data" :key="key">
									<ListArticleComponent :product="product" :limit="limit" :pagenum="pageNum"
														  :searchname="searchName"
														  :key="`${key}_${product.article_number}_list`"/>
									</tbody>
								</table>
							</div>
						</div>
					</div>

				</div>
				<div class="flex flex-col md:flex-row justify-between mb-4">
                    <span class="flex justify-between align-items-center space-x-5 mt-4">
                        <span class="text-base">{{ $t('show') }}</span>
                        <span>
                            <select v-model="limit" class="form-select" @change="shopResultNumber">
                                <option value="20" selected>20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="200">200</option>
                            </select>
                        </span>
                    </span>
					<div class="pagination-block" v-if="GET_ARTICLES.data"> <!--GET_ARTICLES.total_pages--->
						<paginate
							:page-count="GET_ARTICLES.total_pages"
							:click-handler="changePageNumber"
							prev-text="<"
							next-text=">"
							:container-class="'pagination space-x-2'">
						</paginate>
					</div>
				</div>
			</div>
		</div>
		<Notification/>
	</div>
</template>

<script>
import {mapGetters} from 'vuex'
import debounce from 'lodash.debounce'
import Paginate from 'vuejs-paginate'
import GridArticleComponent from './component/GridArticle'
import ListArticleComponent from './component/ListArticle'
import categoryTree from "./component/categoryTree";
import {getCompanyId} from "../../services/storage-window";
import download from "downloadjs";

export default {
	name: 'article-settings',
	components: {
		Notification: () => import('./../../customComponent/NotificationAlert'),
		paginate: Paginate,
		GridArticleComponent,
		ListArticleComponent, categoryTree
	},
	data() {
		return {
			message: this.$t('productEmpty'),
			PaymentType: null,
			grid: true,
			loadingAddon: true,
			addonArticles: [],
			articles: [],
			priceRange: [0, 1000],
			loading: true,
			paramId: null,
			limit: 200,
			showProductMenu: false,
			from: null,
			searchName: '',
			pageNum: '',
			category: null,
			child_category: null,
			company_id: null,
			loadingCategory: true,
			resetTrue: false,
			categoryTreeList: {results: [], count: 1, page: 1, total_pages: 1},
			activeCategoryTree: {
				category: '',
				child_category: '',
				sub_category: ''
			}
		}
	},
	watch: {
		from: function (newVal, oldVal) {
			if (newVal.name !== 'checkout') {
				// this.$store.commit('customer/CLEAR_CART')
			}
		},
		showProductMenu(newVal, oldVal) {
			window.Bus.$emit('show-wrapper', this.showProductMenu)
		}
	},
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			vm.from = from
		})
	},
	computed: {
		...mapGetters({
			GET_ARTICLES: 'customer/GET_ARTICLES',
			GET_CATEGORY: 'customer/GET_CATEGORY',
			COMPANY_SETTINGS: 'customer/GET_COMPANY_SETTINGS'
		})
	},
	mounted() {
		const {company_id} = this.$route.params
		const userDef = window.localStorage.getItem('__userDefr__')

		if (userDef) {
			if (company_id !== userDef) {
				this.$store.commit('customer/CLEAR_CART')
			}
		}

		if (company_id !== undefined && company_id !== 'invalid_company') {
			this.paramId = company_id
			this.getCompanyInfo(this.paramId)

			this.getArticles(company_id, 200)

			window.localStorage.setItem('__userDefr__', company_id)
		} else {
			this.$services.helpers.notification(this.$t('no_company_text'), 'error', this)
		}

		window.Bus.$on('hide-wrapper', e => {
			this.showProductMenu = false
		})
	},
	methods: {
		getCompanyInfo(id) {
			const payload = {
				id: id
			}
			this.$store.dispatch('customer/getCompanySettings', payload).then(res => {
				if ('is_active' in res.data.data) {
					if (res.data.data.is_active === false) {
						this.$router.push({
							name: 'customerdash'
						})
					}
				}
			})
		},
		switchFilter() {
			this.grid = false
		},
		switchGrid() {
			this.grid = true
		},
		getCompanyRecommendedArticle(id) {
			this.loadingAddon = true
			this.$store.dispatch('customer/getCompanyArticlesAddon', {
				URL: `?company_id=${id}`,
				addon: 'recommended'
			}).then(res => {
				if (res.data.data.length > 0) {
					this.addonArticles = res.data.data.slice(0, 5)
				}

				this.loadingAddon = false
			}).catch(er => {
				this.loadingAddon = false
			})
		},
		resetCategory() {
			this.activeCategoryTree = {
				category: '',
				child_category: '',
				sub_category: ''
			}
			this.resetTrue = true
			this.getArticles(this.paramId, this.limit)
		},
		getCompanyCategoryStructure: function (id) {
			this.loadingCategory = true
			this.$store.dispatch('customer/geCompanyArticleStructure', {URL: `?company_id=${id}&limit=50`}).then(resp => {
				this.categoryTreeList.results = resp.data.data.results
				this.loadingCategory = false
			})
		},
		loadMore: function () {
			if (this.categoryTreeList.page <= this.categoryTreeList.total_pages && this.categoryTreeList.page !== this.categoryTreeList.total_pages) {
				this.loading = true
				let pageUrl = `?page=${this.categoryTreeList.page + 1}&limit=50&company_id=${this.paramId}`
				this.$store.dispatch('portal/geCompanyArticleStructure', {data: pageUrl})
					.then(resp => {
						this.categoryTreeList = this.categoryTreeList.results.concat(resp.data.data.results)
						this.categoryTreeList.count = resp.data.data.count
						this.categoryTreeList.page = resp.data.data.page
						this.categoryTreeList.total_pages = resp.data.data.total_pages
						this.loading = false
					}).catch((err) => {
					this.loading = false
					if (err.response.status === 401) {
						this.$store.commit('auth/CLEAR_AUTH_USER', null)
						window.Bus.$emit('sign-out')
					}
				})
			}
		},
		downloadExcelDocument: function () {
			const loading = this.$loading({
				lock: true,
				text: this.$t('pleaseWait'),
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			})
			// this.$services.helpers.notification('documentDownloadInProgress', 'info', this)
			let URL = `/customer_product_download/?company_id=${this.$route.params.company_id}`
			this.$store.dispatch('customer/downloadArticles', {url: URL}).then(res => {
				const content = res.headers['content-type']
				download(res.data, 'b2b_product.xlsx', content)
				loading.close()
			}).catch(() => {
				loading.close()
				this.$services.helpers.notification('Error exporting product data', 'error', this)
			})
		},
		searchInputTrigger: debounce(function (event) {
			if (event.target.value !== '') {

				this.filterSearch(this.paramId, this.limit)
			} else {
				this.searchName = ''
				this.getArticles(this.paramId, this.limit)
			}
		}, 500),
		filterSearch(id, limit) {
			// this.loading = true
			this.$store.commit('customer/UPDATE_ARTICLE_PARAMS', {
				limit: this.limit,
				pagenum: this.pageNum,
				searchname: this.searchName
			})
			this.$store.dispatch('customer/getArticles', {pageUrl: `?company_id=${id}&limit=${limit}&search=${this.searchName}`})
				.then(res => {
			
					this.loading = false
					if (res.data.data.length < 1) {
						this.message = this.$t('searchEmpty')
					} else {
						this.message = this.$t('productEmpty')
					}
				}).catch((err) => {
				this.loading = false
				if (err.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_CUS', null)
					window.Bus.$emit('sign-out')
				}
			})
		},
		changePageNumber(number) {
			let page = `?page=${number}&company_id=${this.paramId}&limit=${this.limit}`
			if (this.searchName !== '' && this.searchName !== undefined && this.searchName !== null) {
				page = page + `&search=${this.searchName}`
			}
			// this.loading = true
			this.pageNum = number
			if (this.category !== null) {
				page += `&category=${this.category}`
			}
			if (this.child_category !== null) {
				page += `&child_category=${this.child_category}`
			}
			this.$store.commit('customer/UPDATE_ARTICLE_PARAMS', {
				limit: this.limit,
				pagenum: this.pageNum,
				searchname: this.searchName
			})
			this.$store.dispatch('customer/getArticles', {pageUrl: page})
				.then(_ => {
					this.loading = false
				}).catch((err) => {
				this.loading = false
				if (err.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_CUS', null)
					window.Bus.$emit('sign-out')
				}
			})
		},
		getArticles(id, limit) {
			this.loading = true
			this.category = null
			this.child_category = null
			this.activeCategoryTree = {
				category: '',
				child_category: '',
				sub_category: ''
			}
			this.$store.dispatch('customer/getArticles', {pageUrl: `?company_id=${id}&limit=${limit}`})
				.then(res => {
			
					this.loading = false
					this.$store.commit('customer/UPDATE_ARTICLE_PARAMS', {
						limit: this.limit,
						pagenum: 1,
						searchname: ''
					})
					if (this.resetTrue === false) {
						this.getProductCategory(id)
						this.getCompanyInfo(id)
						this.getUserBilling()
						this.getCompanyCategoryStructure(id)
						this.getCompanyRecommendedArticle(id)
					}
					this.resetTrue = false

				}).catch((err) => {
				this.loading = false
				if (err.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_CUS', null)
					window.Bus.$emit('sign-out')
				}
			})
		},
		revertProduct() {
			const {company_id} = this.$route.params
			if (company_id !== undefined) {
				this.showProductMenu = false
				this.category = null
				this.child_category = null
				this.getArticles(company_id, 200)
			}
		},
		filterArticlesWithParentCategory(data) {
			const {company_id} = this.$route.params
			if (company_id) {
				this.loading = true
				this.showProductMenu = false
				this.category = data.id
				let category = this.categoryTreeList.results.filter(item => item.id === data.id)
				this.$store.dispatch('customer/getFilterArticles', {query: `company_id=${company_id}&category=${data.id}&limit=${this.limit}`})
					.then(res => {
						this.loading = false
						if (category.length > 0) {
							this.activeCategoryTree = {
								category: '',
								child_category: '',
								sub_category: ''
							}
							this.activeCategoryTree.category = category[0].name
						}
						if (res.data.data.length < 1) {
							this.message = this.$t('filterEmpty')
						} else {
							this.message = this.$t('productEmpty')
						}
					})
					.catch(_ => {
						this.loading = false
					})
			}
		},
		filterArticlesWithCategory(data) {
			const {company_id} = this.$route.params
			if (company_id) {
				this.loading = true
				this.showProductMenu = false
				this.child_category = data.id
				let category = this.categoryTreeList.results.filter(item => item.id === data.category_id)
				this.$store.dispatch('customer/getFilterArticles', {query: `company_id=${company_id}&child_category=${data.id}&limit=${this.limit}`})
					.then(res => {
						this.loading = false
						if (category.length > 0) {
							this.activeCategoryTree = {
								category: '',
								child_category: '',
								sub_category: ''
							}
							this.activeCategoryTree.category = category[0].name
							let child = category[0].child_node.filter(item => item.id === this.child_category)
							if (child.length > 0) {
								this.activeCategoryTree.child_category = child[0].name
							}
						}
						if (res.data.data.length < 1) {
							this.message = this.$t('filterEmpty')
						} else {
							this.message = this.$t('productEmpty')
						}
					})
					.catch(_ => {
						this.loading = false
					})
			}
		},
		getProductCategory(id) {
			this.$store.dispatch('customer/getProductCategory', {query: `company_id=${id}`})
		},
		getUserBilling() {
			this.$store.dispatch('customer/getUserBillingInformation', {data: `?company_id=${this.paramId}`})
		},
		shopResultNumber(e) {
			const {company_id} = this.$route.params
			this.limit = parseInt(e.target.value)
			this.getArticles(company_id, parseInt(e.target.value))
		},
		sortPriceChanges(e) {
			this.$store.commit('customer/SORT_PRICE', e)
		},
		showMegaMenu() {
			this.showProductMenu = !this.showProductMenu
		},
		hideWrapper() {
			this.showMegaMenu()
		}
	}
}
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";

.product_category {
	height: 329px;

}

.recommended-card {
	width: 300px;

}

.megamenu-scroll {
	&::-webkit-scrollbar {
		width: 2px;
	}

	&::-webkit-scrollbar-track {
		background: white !important;
	}

	&::-webkit-scrollbar-thumb {
		background-color: gray !important; /* color of the scroll thumb */
		border-radius: 10px; /* roundness of the scroll thumb */
	}
}

.custom-btn-pay {
	background-color: #F167CA !important;
	border-color: #F167CA !important;
	font-weight: 800;
}

.custom-card-section {
	box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03), 0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03), 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05), 0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
	border-width: 0;
	transition: all .2s;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid rgba(26, 54, 126, 0.125);
}

.custom-save-btn {
	background-color: #2CDCA7 !important;
	border-color: #2CDCA7 !important;
	font-weight: 800;
}

.override-form-field {
	border: 0;
	border-bottom: 2px solid rgb(64, 138, 235);
	box-shadow: none;
	background-color: #fff !important;
	border-radius: unset !important;
}

.col-form-label {
	padding-top: 1rem !important;
	// font-size: 14px !important;
}

.form-group {
	margin-bottom: 0.4rem !important;
}

.form-input {
	font-size: 0.8rem !important;
	padding: 0.2rem 0.75rem !important;
}

.col-form-label {
	padding-bottom: unset !important;
	padding-top: 0.3rem !important;
}

.app-main .app-main__inner {
	padding-top: 20px !important;
}

.table td {
	padding: 0.1rem 0.55rem !important;
}

.form-control {
	border: 1px solid $form-border !important;
	height: unset !important;
	padding: .175rem .75rem !important;
}

.form-select {
	border: 1px solid $form-border !important;
	height: unset !important;
	padding: .175rem 1.5rem !important;
}

.form-control:focus {
	box-shadow: unset !important;
	border: 1px solid $theme-primary-color !important;
}

.btn-border-all {
	border: 1px solid $theme-secondary-color !important;
	color: $theme-secondary-color !important;
}

.category-holder {
	position: relative;
	top: -7px;
}

.text-product-excel {
	color: purple;
}

.product-category-scroll {
	height: 272px;
	overflow-y: scroll;
}

.recommend-product--text {
	font-size: 12px;
	position: relative;
	top: 0px;
}

.clear-category-text {
	font-size: 9px;
	padding-top: 6px;
	color: #1e3a8a;
	font-weight: 600;
}

@media screen and (max-width: 768px) {
	.recommended-card {
		width: 322px;
	}
}

</style>
