<template>
	<tr class="bg-white rounded p-1 shadow-sm roboto-font">
		<td class="cursor-pointer" v-on:click="showImages">
			<div class="flex flex-col w-40 list-article-view">
				<el-carousel :interval="5000" indicator-position="none">
					<el-carousel-item v-for="(item, i) in product.images" :key="i">
                        <span class="img">
								<ImageCard :source="item.image" :custom_class="'carousel-cell-image'"></ImageCard>
                        </span>
					</el-carousel-item>
				</el-carousel>
			</div>
		</td>
		<td class="cursor-pointer" v-on:click="openProductDetail(product.id)"><span>{{ product.article_number }}</span>
		</td>
		<td class="cursor-pointer" v-on:click="openProductDetail(product.id)"><span>{{
				cleanData(product.description)
			}}</span></td>
		<td class="cursor-pointer" v-on:click="openProductDetail(product.id)">
			<div v-if="GET_COMPANY.hide_stock_quantity === false && hidden_fields.stock_quantity === false">
				<span
					v-if="parseInt(product.quantity_in_stock) >= parseFloat(GET_COMPANY.threshold_product_visibility)">
					<span>{{ $t('disposableQty') }}:</span>
				<span class="ml-1"
				>{{
						product.quantity_in_stock
					}}</span>
				</span>
				<span v-else>(<span class="font-semibold">{{ $t('unavailable') }}</span>)</span>
			</div>

		</td>
		<td class="cursor-pointer" v-on:click="openProductDetail(product.id)">
			<span class="text-gray-500" v-if="hasDiscount() === true && hidden_fields.discounted_price === false">{{
					$services.helpers.formatPrice(cleanData(product.discounted_price))
				}} :- </span>
			<span class="text-gray-500" v-if="hasDiscount() === false && hidden_fields.sales_price === false">{{
					$services.helpers.formatPrice(cleanData(product.sales_price))
				}} {{ product.currency }} </span>
			<div v-if="product.hasOwnProperty('standard_price')">
				{{ $t('standard_price') }}: {{
					product.standard_price
				}}
			</div>
		</td>
		<td>
            <span>
				<input-number @onchange="onChangeInput" :num="num"/>
            </span>
		</td>
		<td>
			<button
				class="border-2 hover:bg-yellow-500 hover:text-white border-yellow-500 px-4 py-2 text-yellow-500 rounded-md"
				@click="addProductCart" :disabled='num < 1'>Beställ
			</button>
		</td>
	</tr>

</template>

<script>
import {mapGetters} from 'vuex'
import moment from "moment";
import ImageCard from "../../../customComponent/ImageCard";
import inputNumber from "./inputNumber";
import {BUYER_HIDDEN_FIELDS} from "../../../services/constant";
import {getUserSetting} from "../../../services/storage-window";
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import Vue from 'vue'

Vue.use(VueViewer)
export default {
	name: 'article-component',
	components: {ImageCard, inputNumber},
	props: {
		product: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			addingToCart: false,
			imageBaseServerURL: 'https://api.b2bportalen.se/media/',
			num: 0,
			prevNum: null,
			flickityOptions: {
				initialIndex: 3,
				prevNextButtons: true,
				pageDots: false,
				wrapAround: false,
				draggable: '>1',
				fullscreen: true,

				// any options from Flickity can be used
			},
			hidden_fields: {...JSON.parse(JSON.stringify(BUYER_HIDDEN_FIELDS))},
			productImages: []
		}
	},
	computed: {
		...mapGetters({
			GET_ARTICLES: 'customer/GET_ARTICLES',
			CART_PRODUCT: 'customer/CART_PRODUCT',
			GET_COMPANY: 'customer/GET_COMPANY_SETTINGS',
			BUYER_SETTINGS: 'customer/GET_USER_SETTINGS',
			HIDE_FIELD_STATUS: 'customer/GET_USER_SETTINGS_HIDE_FIELD_STATUS'
		})
	},
	watch: {},
	mounted() {
		window.Bus.$on('revert-input-to-zero', e => {
			this.num = 0
		})
		this.disabledFields(this.HIDE_FIELD_STATUS)
		window.Bus.$on('trigger-hidden-field-event', e => {
			this.disabledFields(e)
		})
		this.productImages = []
		if (this.product.images.length > 0) {
			this.product.images.map(item => {
				this.productImages.push(item.image)
			})
		}
	},
	methods: {
		showImages() {
			if (this.productImages.length > 0) {
				this.$viewerApi({
					images: this.productImages,
				})
			}

		},
		onChangeInput(mode) {
			this.num = mode
		},
		handleInput() {
			if (parseInt(this.num) === undefined || parseInt(this.num) === null || isNaN(parseInt(this.num))) {
				this.num = 1
			}
		},
		addProductCart() {
			// if (parseInt(this.product.quantity_in_stock) > 0) {
			if (this.num > 0) {
				window.Bus.$emit('add-to-cart', {product: this.product, qty: this.num})
			} else {
				this.$store.commit('customer/REMOVE_CART_PRODUCT', {
					product: this.product,
					remove: true,
					qty: this.num
				})
				window.Bus.$emit('message', {data: this.$t('itemRemove')})
				this.prevNum = 0
			}
			// }
		},
		handleChange(e) {
			if (e === undefined) {
				this.num = 0
			} else {
				this.num = e
			}
		},
		hasDiscount() {
			let currentDate = moment().format('YYYY-MM-DD')
			if (this.product.scheduled_campaign_start_date !== null && this.product.scheduled_campaign_end_date !== null && this.product.scheduled_campaign_start_date !== '' && this.product.scheduled_campaign_end_date !== '') {
				let startDate = moment(this.product.scheduled_campaign_start_date).format('YYYY-MM-DD')
				let endDate = moment(this.product.scheduled_campaign_end_date).format('YYYY-MM-DD')
				if (currentDate >= startDate && currentDate <= endDate) {
					return true
				} else {
					return false
				}
			} else {
				return false
			}
		},
		openProductModal(data) {
			window.Bus.$emit('open-product', data)
		},
		openProductDetail(itemID) {
			this.$router.push({
				name: 'single-product',
				params: {company_id: this.$route.params.company_id, product_id: itemID}
			})
		},

		cleanData: function (name) {
			if (['nan'].includes(name)) {
				return ''
			} else {
				return name
			}

		},
		disabledFields: function (status) {
			if (getUserSetting()) {
				if (status === true) {
					let fields = getUserSetting() || {}
					let hidden_fields = fields.hidden_fields
					let visible_fields = fields.visible_fields
					visible_fields.map(item => {
						this.hidden_fields[item] = false
					})
					hidden_fields.map(item => {
						this.hidden_fields[item] = true
					})
				} else {
					Object.keys(this.hidden_fields).map(item => {
						this.hidden_fields[item] = false
					})
				}

			}


		},
	}
}
</script>

<style lang="scss" scoped>
.rm-width {
	width: unset !important;
}

.table th, .table td {
	border: unset !important;
}

.unset-pad {
	padding: .375rem 1.5rem !important;
}

.el-input-number {
	width: 130px !important;
	height: 10px;
}

.medium-zoom-image {
	cursor: pointer;
	cursor: zoom-in;
	position: absolute;
	transition: transform .3s cubic-bezier(.2, 0, .2, 1) !important;
	z-index: 99
}

.input-el {
	border: 1px solid #f1eded;
	width: 54px;
	height: 32px;
	text-align: center;
}

.carousel-cell img {
	display: block;
	max-height: 100%;
}

.increase-button {
	width: 28px;
	font-size: 12px;
	background: #f5f7fa;
	height: 32px;
	padding: 8px;
	border: 1px solid #f5f7fa;
	border-radius: 0px;
}
</style>
